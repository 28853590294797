import { Controller } from "@hotwired/stimulus";
import { Html5Qrcode } from "html5-qrcode";

// Connects to data-controller="qrcode"
export default class extends Controller {
  /**
   * Instance du Html5Qrcode
   */
  #qrCodeReader = new Html5Qrcode("qrcode-view");

  /**
   * Targets du controller.
   */
  static targets = ["file", "value", "submitButton", "cancelButton"];

  declare fileTarget: HTMLInputElement;
  declare hasFileTarget: boolean;
  declare valueTarget: HTMLElement;
  declare hasValueTarget: boolean;
  declare submitButtonTarget: HTMLElement;
  declare hasSubmitButtonTarget: boolean;
  declare cancelButtonTarget: HTMLElement;
  declare hasCancelButtonTarget: boolean;

  connect() {
    // this.fileTarget.addEventListener("change", this.#handleFileChanged);
  }

  disconnect() {
    this.#stopCameraScan();
  }

  /**
   * Déclenchement de la sélection d'une image.
   */
  // selectFile = async () => {
  //   // On s'assure que le scan par caméra n'est pas actif en même temps
  //   await this.#stopCameraScan();

  //   this.#updateValue(null);
  //   this.fileTarget.click();
  // };

  /**
   * Démarrage du scan par caméra. Sur téléphone, la caméra à l'arrière est choisie (facingMode: environment).
   */
  startCameraScan = (event) => {
    event.preventDefault();
    this.#updateValue(null);

    const config = {
      fps: 10,
      qrbox: {
        width: 250,
        height: 250,
      },
    };

    this.#qrCodeReader
      .start({ facingMode: "environment" }, config, this.#handleCameraQrCode)
      .then(() => {
        // Scroll automatique une fois que la caméra est prête à scanner
        this.element.scrollIntoView({ behavior: "smooth", block: "center" });
      })
      .catch((error) => {
        // Traitement si le scan par caméra n'a pas pu être démarré
      });
    if (this.hasSubmitButtonTarget && this.hasCancelButtonTarget) {
      this.submitButtonTarget.classList.add("hidden");
      this.cancelButtonTarget.classList.remove("hidden");
    }
  };

  /**
   * Déclenchement du scan de l'image sélectionnée et décodage du QR code.
   * @param {Event} e événement change de l'input contenant l'image sélectionnée
   */
  // #handleFileChanged = (e) => {
  //   // Vérification qu'une image a été sélectionnée
  //   if (e.target.files.length === 0) return;

  //   this.#qrCodeReader.scanFile(e.target.files[0], true)
  //     .then((decodedText) => {
  //       this.#updateValue(decodedText);
  //     })
  //     .catch((error) => {
  //       // Traitement si aucun QR code n'a été décodé
  //       this.#updateValue(null);
  //     });
  // };

  stopCameraScan = () => {
    this.#stopCameraScan();
    if (this.hasSubmitButtonTarget && this.hasCancelButtonTarget) {
      this.submitButtonTarget.classList.remove("hidden");
      this.valueTarget.classList.add("hidden");
      this.cancelButtonTarget.classList.add("hidden");
    }
  };
  /**
   * Arrêt du scan par caméra s'il est actif.
   */
  #stopCameraScan = () =>
    new Promise<void>(async (resolve) => {
      if (this.#qrCodeReader.isScanning) {
        await this.#qrCodeReader.stop();
      }
      resolve();
    });

  /**
   * Callback appelé lorsqu'un QR code a été scanné avec succès par la caméra.
   * @param {String} decodedText la valeur du QR code
   * @param {Object} decodedResult un objet contenant des infos à propos du QR code scanné
   */
  #handleCameraQrCode = (decodedText, _decodedResult) => {
    this.#updateValue(decodedText);
  };

  /**
   * Mise à jour de la valeur et de son rendu dans l'UI.
   */
  #updateValue = (newValue) => {
    if (this.hasValueTarget) {
      // Nettoyage du canvas et de l'input fichier si aucune valeur
      if (!newValue) {
        this.#qrCodeReader.clear();
        this.fileTarget.value = "";
        this.valueTarget.classList.add("hidden");
      } else {
        this.valueTarget.classList.remove("hidden");
      }

      // Mise à jour dans l'UI et scroll automatique
      if (newValue && newValue.startsWith("http")) {
        // let link = `<a href="${newValue}" class="button bg-primary hover:bg-primary-light">Voir l'inscription</a>`;
        // this.valueTarget.innerHTML = link;
        const spinner = document.getElementById("spinner");
        if (spinner) {
          spinner.classList.remove("hidden");
        }
        window.location.href = newValue;
      } else {
        this.valueTarget.textContent = newValue || "";
      }
      this.element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
}
