import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="vanilla-nested"
export default class extends Controller {
  static values = { limit: Number };

  declare limitValue: number;
  declare hasLimitValue: boolean;

  connect() {
    if (this.hasLimitValue) {
      const limit = this.limitValue;
      document.addEventListener("vanilla-nested:fields-added", (e) => {
        let nestedElements = document.getElementsByClassName("nested-element");
        if (nestedElements && limit && nestedElements.length > limit) {
          for (let i = limit; i < nestedElements.length; i++) {
            nestedElements[i].previousSibling?.remove();
            nestedElements[i]?.remove();
          }
        }
      });
    }
  }
}
