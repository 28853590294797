import { Application } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "$railsRoot/tailwind.config";
import Croppr from "croppr";
import "vanilla-nested";
import "trix";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Extract breakpoints from the Tailwind configuration so we can use them inside Stimulus controllers
const { theme } = resolveConfig(tailwindConfig);
const breakpoints = Object.fromEntries(
  Object.entries(theme!.screens!).map(([k, v]) => [k, parseInt((v as string).replace("px", ""))]),
);

export { application, breakpoints };

document.addEventListener("turbo:before-fetch-request", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.remove("hidden");
  }
});
document.addEventListener("turbo:frame-render", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});
document.addEventListener("turbo:load", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});
document.addEventListener("turbo:before-fetch-response", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  displayFlash();
  hideFlash();
});
document.addEventListener("turbo:render", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});

function hideFlash() {
  const notice = document.getElementById("notice");
  if (notice) {
    setInterval(function () {
      notice.classList.add("hidden");
    }, 2500);
  }
  const alert = document.getElementById("alert");
  if (alert) {
    setInterval(function () {
      alert.classList.add("hidden");
    }, 2500);
  }
}

function displayFlash() {
  const notice = document.getElementById("notice");
  if (notice) {
    notice.classList.remove("hidden");
  }
  const alert = document.getElementById("alert");
  if (alert) {
    alert.classList.remove("hidden");
  }
}

Turbo.setConfirmMethod((message, element) => {
  return new Promise((resolve) => {
    const backdrop = document.createElement("div");
    backdrop.classList.add("fixed", "inset-0", "bg-black", "bg-opacity-50", "z-40");

    const dialog = document.createElement("dialog");
    dialog.classList.add("p-8", "rounded-common", "shadow-xl");
    dialog.innerHTML = `
      <p class="mt-4 mb-8">${message}</p>
      <div class="flex justify-end items-center">
        <button class="submit-button bg-gray-300 hover:bg-gray-200 mr-4" data-confirm="false">Annuler</button>
        <button class="button" data-confirm="true">OK</button>
      <div>
    `;
    document.body.appendChild(backdrop);
    document.body.appendChild(dialog);
    dialog.showModal();

    dialog.addEventListener("close", () => {
      backdrop.remove();
      resolve(dialog.returnValue === "true");
    });

    dialog.addEventListener("click", (event) => {
      if (event.target != null && event.target.tagName === "BUTTON") {
        dialog.close();
        resolve(event.target.dataset.confirm === "true");
      }
    });
  });
});
