import { Controller } from "@hotwired/stimulus";
// const emojiRegex = require("emoji-regex");
// Connects to data-controller="forms"
export default class extends Controller {
  static targets = [
    "changeTrigger",
    "divOptionalFields",
    "changeRadioButtonTrigger",
    "changeCheckboxTrigger",
    "toggleRequired",
    "inputText",
    "inputToCopy",
    "copyInput",
    "searchedElt",
    "noResult",
    "nbResults",
  ];
  static values = { display: String, mandatory: Boolean, required: String };

  declare changeTriggerTarget: HTMLInputElement;
  declare changeTriggerTargets: HTMLInputElement[];
  declare hasChangeTriggerTarget: boolean;

  declare divOptionalFieldsTarget: HTMLElement;
  declare hasDivOptionalFieldsTarget: boolean;
  declare displayValue: string;
  declare hasDisplayValue: boolean;
  declare changeRadioButtonTriggerTargets: HTMLInputElement[];
  declare hasChangeRadioButtonTriggerTarget: boolean;
  declare changeCheckboxTriggerTarget: HTMLInputElement;
  declare hasChangeCheckboxTriggerTarget: boolean;
  declare toggleRequiredTarget: HTMLInputElement;
  declare hasToggleRequiredTarget: boolean;
  declare requiredValue: string;
  declare hasRequiredValue: boolean;
  declare mandatoryValue: boolean;
  declare hasMandatoryValue: boolean;
  declare inputTextTarget: HTMLInputElement;
  declare hasInputTextTarget: boolean;
  declare searchedEltTargets: HTMLElement[];
  declare hasSearchedEltTarget: boolean;
  declare noResultTarget: HTMLElement;
  declare hasNoResultTarget: boolean;
  declare nbResultsTarget: HTMLElement;
  declare hasNbResultsTarget: boolean;

  declare htmlFields: string;

  declare inputToCopyTarget: HTMLInputElement;
  declare hasInputToCopyTarget: boolean;
  declare copyInputTarget: HTMLInputElement;
  declare hasCopyInputTarget: boolean;

  connect() {
    if (this.hasDivOptionalFieldsTarget) {
      this.htmlFields = this.divOptionalFieldsTarget.innerHTML;
      this.toggleOptionalFields();
    }
    if (this.hasChangeTriggerTarget && this.hasToggleRequiredTarget) {
      this.toggleRequired();
    }
  }

  toggleOptionalFields() {
    if (this.hasDivOptionalFieldsTarget && this.hasDisplayValue) {
      if (this.hasChangeTriggerTarget) {
        if (this.displayValue === this.changeTriggerTarget.value && this.divOptionalFieldsTarget.innerHTML === "") {
          this.divOptionalFieldsTarget.innerHTML = this.htmlFields;
        } else if (
          this.displayValue !== this.changeTriggerTarget.value &&
          this.divOptionalFieldsTarget.innerHTML !== ""
        ) {
          this.divOptionalFieldsTarget.innerHTML = "";
        }
      } else if (this.hasChangeRadioButtonTriggerTarget) {
        for (let radioButton of this.changeRadioButtonTriggerTargets) {
          if (
            radioButton.value == this.displayValue &&
            radioButton.checked &&
            this.divOptionalFieldsTarget.innerHTML === ""
          ) {
            this.divOptionalFieldsTarget.innerHTML = this.htmlFields;
          } else if (
            radioButton.value != this.displayValue &&
            radioButton.checked &&
            this.divOptionalFieldsTarget.innerHTML !== ""
          ) {
            this.divOptionalFieldsTarget.innerHTML = "";
          }
        }
      }
    } else if (this.hasDivOptionalFieldsTarget && this.hasChangeCheckboxTriggerTarget) {
      this.divOptionalFieldsTarget.innerHTML = this.changeCheckboxTriggerTarget.checked ? "" : this.htmlFields;
    }
  }

  toggleRequired() {
    if (this.hasChangeTriggerTarget && this.hasToggleRequiredTarget) {
      // valeur qui passe la target à required
      const requiredValue = this.hasRequiredValue ? this.requiredValue : "";
      if (this.changeTriggerTargets && this.changeTriggerTargets.length > 1) {
        const allInputsBlank = this.changeTriggerTargets.every((input) => {
          return input.value == "" || input.value == null;
        });
        this.toggleRequiredTarget.required = allInputsBlank;
      } else {
        this.toggleRequiredTarget.required = this.changeTriggerTarget.value == requiredValue;
      }
    } else if (this.hasChangeTriggerTarget && this.changeTriggerTarget.classList.contains("hidden")) {
      this.toggleRequiredTarget.required = false;
    }
  }

  customStep(event) {
    event.preventDefault();
    let value = parseFloat(event.target.value);
    if (event.target.value == "") {
      value = 0;
    }
    // wheelDelta determine si scroll vers le haut (>0) ou vers le bas (<0)
    if (event.wheelDelta > 0) {
      event.target.value = Number.isInteger(value) ? value + 1 : Math.ceil(value);
      event.target.dispatchEvent(new Event("input"));
    } else {
      let newValue = Number.isInteger(value) ? value - 1 : Math.floor(value);
      event.target.value = newValue > 0 ? newValue : 0;
      event.target.dispatchEvent(new Event("input"));
    }
  }

  formatNumberInput(event) {
    let value = parseFloat(event.target.value);
    if (value < 0) {
      event.target.value = 0;
      return;
    }

    let regex_too_much_decimals = /^\d+(\,|\.)\d{3,}$/;
    if (regex_too_much_decimals.test(value.toString())) {
      event.target.value = value.toFixed(2);
      event.target.dispatchEvent(new Event("input"));
    }
  }

  formatInputTextToNumber(event) {
    if (event.target.value != null && event.target.value != "0") {
      let input = event.target.value;
      event.target.value = "";
      for (let char of input) {
        if (/^\d$/.test(char)) {
          event.target.value += char;
        }
      }
      let min = event.target.min == "" ? 0 : parseInt(event.target.min);
      let max = event.target.max == "" ? null : parseInt(event.target.max);
      let quantity = event.target.value == "" ? 0 : parseInt(event.target.value);
      if (quantity < min) {
        event.target.value = min.toString();
      } else if (max && quantity > max) {
        event.target.value = max.toString();
      }
    }
  }

  increment() {
    if (this.hasInputTextTarget) {
      let max = this.inputTextTarget.max == "" ? null : parseInt(this.inputTextTarget.max);
      let quantity = this.inputTextTarget.value == "" ? 0 : parseInt(this.inputTextTarget.value);

      this.inputTextTarget.value =
        (max != null && quantity < max) || max == null ? (quantity + 1).toString() : max.toString();
      this.inputTextTarget.dispatchEvent(new Event("input"));
    }
  }

  decrement() {
    if (this.hasInputTextTarget) {
      let min = this.inputTextTarget.min == "" ? 0 : parseInt(this.inputTextTarget.min);
      let quantity = this.inputTextTarget.value == "" ? 0 : parseInt(this.inputTextTarget.value);
      if (quantity > min) {
        this.inputTextTarget.value = (quantity - 1).toString();
        this.inputTextTarget.dispatchEvent(new Event("input"));
      }
    }
  }

  increment_or_decrement(event) {
    if (this.hasInputTextTarget) {
      this.inputTextTarget.value = event.target.value && event.target.value != "" ? "1" : "";
      this.inputTextTarget.dispatchEvent(new Event("input"));
    }
  }

  resetForm(event) {
    const inputs = this.element.getElementsByTagName("input");
    for (let input of inputs) {
      if (input != event.target) {
        input.value = "";
      }
    }
  }

  copyInput() {
    if (
      this.hasInputToCopyTarget &&
      this.hasCopyInputTarget &&
      this.inputToCopyTarget.value != "" &&
      (this.copyInputTarget.value == null || this.copyInputTarget.value == "")
    ) {
      this.copyInputTarget.value = this.inputToCopyTarget.value;
    }
  }

  search(event) {
    if (this.hasSearchedEltTarget) {
      let input = event.target.value.trim().toLowerCase();
      let nbResults = 0;

      if (input && input != "") {
        this.searchedEltTargets.forEach((elt) => {
          // Si chiffres on cherche sur no dossard
          if (input.match(/\d+/g)) {
            if (elt.dataset.number?.startsWith(input)) {
              elt.classList.remove("hidden");
              nbResults += 1;
            } else {
              elt.classList.add("hidden");
            }
            //  sinon sur nom prénom
          } else {
            if (elt.dataset.name?.includes(input)) {
              elt.classList.remove("hidden");
              nbResults += 1;
            } else {
              elt.classList.add("hidden");
            }
          }
        });
      } else {
        this.searchedEltTargets.forEach((elt) => {
          elt.classList.remove("hidden");
          nbResults += 1;
        });
      }
      if (this.searchedEltTargets.every((elt) => elt.classList.contains("hidden")) && this.hasNoResultTarget) {
        this.noResultTarget.classList.remove("hidden");
        if (this.hasNbResultsTarget) {
          this.nbResultsTarget.innerText = "0";
        }
      } else {
        this.noResultTarget.classList.add("hidden");
        if (this.hasNbResultsTarget) {
          this.nbResultsTarget.innerText = nbResults.toString();
        }
      }
    }
  }

  // removeEmojis(event) {
  //   let regex = emojiRegex();
  //   event.target.value = event.target.value.replaceAll(regex, "");
  // }
}
